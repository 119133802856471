var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "my-0", attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticClass: "pb-0 pt-3" },
            [
              _c("submission-alert-message", {
                ref: "banner",
                attrs: {
                  "should-scroll-to-top": "",
                  message: _vm.bannerMessage,
                },
                model: {
                  value: _vm.showBannerCondition,
                  callback: function ($$v) {
                    _vm.showBannerCondition = $$v
                  },
                  expression: "showBannerCondition",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-card",
                { staticClass: "pa-8 overflow-auto" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: _vm.formNames[0],
                                  model: {
                                    value: _vm.formValid[0],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValid, 0, $$v)
                                    },
                                    expression: "formValid[0]",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      placeholder: !_vm.$language
                                        ? "Enter your package title here..."
                                        : "আপনার প্যাকেজের শিরোনাম লিখুন",
                                      height: "45",
                                      rules: [_vm.required],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Package Title"
                                                    : "প্যাকেজ শিরোনাম"
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.packageData.package_title,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.packageData,
                                          "package_title",
                                          $$v
                                        )
                                      },
                                      expression: "packageData.package_title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: _vm.formNames[1],
                                  model: {
                                    value: _vm.formValid[1],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValid, 1, $$v)
                                    },
                                    expression: "formValid[1]",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "return-object": "",
                                      height: "auto",
                                      items: _vm.courses,
                                      "item-text": "course_title",
                                      multiple: "",
                                      outlined: "",
                                      chips: "",
                                      "deletable-chips": "",
                                      placeholder: !_vm.$language
                                        ? "Select Courses"
                                        : "কোর্স সিলেক্ট করুন",
                                      rules: _vm.rules.select_required,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Select Courses"
                                                    : "কোর্স সিলেক্ট করুন"
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.selectedCourses,
                                      callback: function ($$v) {
                                        _vm.selectedCourses = $$v
                                      },
                                      expression: "selectedCourses",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "overflow-auto pa-5",
                                  attrs: { height: "350", outlined: "" },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language
                                          ? "Available Batches"
                                          : "উপলব্ধ ব্যাচ"
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        _vm._l(
                                          _vm.selectedCourses,
                                          function (course) {
                                            return _c(
                                              "v-expansion-panel",
                                              {
                                                key: course,
                                                staticClass: "mt-3 pa-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getBatchesUnderCourses(
                                                      course.course_id,
                                                      course.partner_code
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-expansion-panel-header", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        course.course_title
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "overflow-auto pa-5",
                                                        attrs: {
                                                          height: "150",
                                                          elevation: "0px",
                                                        },
                                                      },
                                                      [
                                                        _vm.activeBatches
                                                          .length != 0
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                _vm.activeBatches,
                                                                function (
                                                                  batch
                                                                ) {
                                                                  return _c(
                                                                    "v-row",
                                                                    {
                                                                      key: batch,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              multiple:
                                                                                "",
                                                                              value:
                                                                                batch,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .selectedBatches[
                                                                                  course
                                                                                    .course_id
                                                                                ],
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.selectedBatches,
                                                                                    course.course_id,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "selectedBatches[course.course_id]",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                batch.batch_title
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _c("div", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    !_vm.$language
                                                                      ? "This course has no batches"
                                                                      : "এই কোর্সের কোন ব্যাচ নেই"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                    "border-color": "#c7c7c7",
                                  },
                                  style:
                                    _vm.imageValidationMessage !== undefined
                                      ? "border-color: #ff5252"
                                      : "",
                                  attrs: {
                                    outlined: "",
                                    height: "100%",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("image-input", {
                                    staticClass: "mt-3",
                                    attrs: { rules: _vm.rules.imageRules },
                                    on: { validation: _vm.valid_image },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "m-button",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [_vm._v("image")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.$language
                                                            ? "Select an image"
                                                            : "ছবি সিলেক্ট করুন"
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.packageData.package_img,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.packageData,
                                          "package_img",
                                          $$v
                                        )
                                      },
                                      expression: "packageData.package_img",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text",
                                  },
                                  [_vm._v(_vm._s(_vm.imageValidationMessage))]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticStyle: { "margin-top": "15px" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: _vm.formNames[2],
                                  model: {
                                    value: _vm.formValid[2],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValid, 2, $$v)
                                    },
                                    expression: "formValid[2]",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      placeholder: !_vm.$language
                                        ? "Enter package price..."
                                        : "প্যাকেজ মূল্য প্রদান করুন",
                                      height: "45",
                                      type: "number",
                                      rules: [
                                        _vm.digitsOnly,
                                        ..._vm.positiveNumberRule,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Price"
                                                    : "মূল্য"
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.packageData.price,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.packageData, "price", $$v)
                                      },
                                      expression: "packageData.price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: _vm.formNames[3],
                                  model: {
                                    value: _vm.formValid[3],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValid, 3, $$v)
                                    },
                                    expression: "formValid[3]",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      items: _vm.paymentTypes,
                                      "item-text": !_vm.$language ? "en" : "bn",
                                      "item-value": "en",
                                      rules: [(v) => _vm.required(v)],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Payment types"
                                                    : "পেমেন্টের ধরন"
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.packageData.payment_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.packageData,
                                          "payment_type",
                                          $$v
                                        )
                                      },
                                      expression: "packageData.payment_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  label: !_vm.$language
                                    ? "Discount types"
                                    : "ডিসকাউন্টের ধরন",
                                  items: _vm.discountTypes,
                                  "item-text": !_vm.$language ? "en" : "bn",
                                  "item-value": "en",
                                },
                                model: {
                                  value: _vm.packageData.discount_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.packageData,
                                      "discount_type",
                                      $$v
                                    )
                                  },
                                  expression: "packageData.discount_type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    outlined: "",
                                    label: !_vm.$language
                                      ? "Discount"
                                      : "ডিসকাউন্ট",
                                    height: "45",
                                    type: "number",
                                    rules: [
                                      ..._vm.rules.discountRule,
                                      ..._vm.positiveNumberRule,
                                    ],
                                  },
                                  model: {
                                    value: _vm.packageData.discount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.packageData, "discount", $$v)
                                    },
                                    expression: "packageData.discount",
                                  },
                                },
                                [_vm._v(" > ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$language
                                ? "Select a Timeline"
                                : "সময়সীমা নির্বাচন করুন"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-form",
                                            {
                                              ref: _vm.formNames[4],
                                              model: {
                                                value: _vm.formValid[4],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formValid,
                                                    4,
                                                    $$v
                                                  )
                                                },
                                                expression: "formValid[4]",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        readonly: "",
                                                        rules: [_vm.required],
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function () {
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.$language
                                                                        ? "From"
                                                                        : "হইতে"
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.packageData
                                                            .start_date,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.packageData,
                                                            "start_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "packageData.start_date",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.fromDateMenu,
                                    callback: function ($$v) {
                                      _vm.fromDateMenu = $$v
                                    },
                                    expression: "fromDateMenu",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.fromDateMenu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.packageData.start_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.packageData,
                                          "start_date",
                                          $$v
                                        )
                                      },
                                      expression: "packageData.start_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-form",
                                            {
                                              ref: _vm.formNames[5],
                                              model: {
                                                value: _vm.formValid[5],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formValid,
                                                    5,
                                                    $$v
                                                  )
                                                },
                                                expression: "formValid[5]",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        readonly: "",
                                                        rules: [_vm.required],
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function () {
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      !_vm.$language
                                                                        ? "To"
                                                                        : "পর্যন্ত"
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.packageData
                                                            .expiry_date,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.packageData,
                                                            "expiry_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "packageData.expiry_date",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.toDateMenu,
                                    callback: function ($$v) {
                                      _vm.toDateMenu = $$v
                                    },
                                    expression: "toDateMenu",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", scrollable: "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.toDateMenu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.packageData.expiry_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.packageData,
                                          "expiry_date",
                                          $$v
                                        )
                                      },
                                      expression: "packageData.expiry_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "m-rounded-button",
                            {
                              staticClass: "btn-outlined",
                              attrs: {
                                outlined: "",
                                color: "red accent-3",
                                "data-qa": "cancel-button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "courses",
                                    query: { tab: "package" },
                                  })
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("cancel"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language ? "Cancel" : "বাতিল করুন"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "m-rounded-button",
                            {
                              staticClass: "ml-2 btn-outlined",
                              attrs: { depressed: "" },
                              on: { click: _vm.savePackage },
                            },
                            [
                              _c("m-icon", { attrs: { left: "" } }, [
                                _vm._v("save_alt"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(!_vm.$language ? "Save" : "সেভ করুন") +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }