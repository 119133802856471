import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

export default {
  createPackage(partner_code, packageData, opts = {}) {
    let imgFile = undefined;
    if ("package_img" in packageData) {
      packageData.package_image = packageData.package_img.file.name;
      imgFile = packageData.package_img.file;
    }
    let outgoing = { partner_code, ...packageData };
    return $backend
      .post("teachers_create_package", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(response => {
        if ("package_img" in packageData) {
          $backend.upload_to_s3(response.data.content.upload_info, imgFile);
        }
        return response;
      });
  },

  getAllPackages(partner_code, package_type) {
    let outgoing = { partner_code, package_type };
    return $backend
      .post("teachers_get_packages", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(resp => resp.data.content.packages);
  },

  getPackage(partner_code, package_type, package_id) {
    let outgoing = { partner_code, package_type, package_id };
    return $backend
      .post("teachers_get_package", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(resp => resp.data.content.package);
  },

  deletePackage(partner_code, package_type, package_id) {
    let outgoing = { partner_code, package_type, package_id };
    return $backend
      .post("teachers_delete_package", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(resp => resp.data.content);
  },

  updatePackage(partner_code, package_type, package_id, data) {
    let imgFile = undefined;
    if ("package_img" in data) {
      data.package_image = data.package_img.file.name;
      imgFile = data.package_img.file;
    }
    let outgoing = { partner_code, package_type, package_id, ...data };
    return $backend
      .post("teachers_update_package", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(response => {
        if ("package_img" in data) {
          $backend.upload_to_s3(response.data.content.upload_info, imgFile);
        }
        return response;
      });
  }
};
