<template>
  <v-row justify="center" class="my-0">
    <v-col cols="12" class="py-0">
      <v-container class="pb-0 pt-3">
        <submission-alert-message
          should-scroll-to-top
          ref="banner"
          v-model="showBannerCondition"
          :message="bannerMessage"
        ></submission-alert-message>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container>
        <v-card class="pa-8 overflow-auto">
          <v-form>
            <v-row>
              <v-col>
                <v-form :ref="formNames[0]" v-model="formValid[0]">
                  <v-text-field
                    v-model="packageData.package_title"
                    outlined
                    :placeholder="
                      !$language
                        ? 'Enter your package title here...'
                        : 'আপনার প্যাকেজের শিরোনাম লিখুন'
                    "
                    height="45"
                    :rules="[required]"
                  >
                    <template #label>
                      {{ !$language ? "Package Title" : "প্যাকেজ শিরোনাম" }}
                      <span style="color: red">*</span>
                    </template>
                  </v-text-field>
                </v-form>
              </v-col>
              <v-col>
                <v-form :ref="formNames[1]" v-model="formValid[1]">
                  <v-select
                    v-model="selectedCourses"
                    return-object
                    height="auto"
                    :items="courses"
                    item-text="course_title"
                    multiple
                    outlined
                    chips
                    deletable-chips
                    :placeholder="
                      !$language ? 'Select Courses' : 'কোর্স সিলেক্ট করুন'
                    "
                    :rules="rules.select_required"
                  >
                    <template #label>
                      {{ !$language ? "Select Courses" : "কোর্স সিলেক্ট করুন" }}
                      <span style="color: red">*</span>
                    </template>
                  </v-select>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card height="350" outlined class="overflow-auto pa-5">
                  <p>{{ !$language ? "Available Batches" : "উপলব্ধ ব্যাচ" }}</p>
                  <div>
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="course in selectedCourses"
                        :key="course"
                        class="mt-3 pa-2"
                        @click="
                          getBatchesUnderCourses(
                            course.course_id,
                            course.partner_code
                          )
                        "
                      >
                        <v-expansion-panel-header>
                          {{ course.course_title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card
                            class="overflow-auto pa-5"
                            height="150"
                            elevation="0px"
                          >
                            <div v-if="activeBatches.length != 0">
                              <v-row
                                v-for="batch in activeBatches"
                                :key="batch"
                              >
                                <v-checkbox
                                  v-model="selectedBatches[course.course_id]"
                                  multiple
                                  :value="batch"
                                >
                                </v-checkbox>
                                <v-col class="mt-2">
                                  {{ batch.batch_title }}
                                </v-col>
                              </v-row>
                            </div>
                            <div v-else>
                              {{
                                !$language
                                  ? "This course has no batches"
                                  : "এই কোর্সের কোন ব্যাচ নেই"
                              }}
                            </div>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  outlined
                  height="100%"
                  width="100%"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-color: #c7c7c7;
                  "
                  :style="
                    imageValidationMessage !== undefined
                      ? 'border-color: #ff5252'
                      : ''
                  "
                >
                  <image-input
                    v-model="packageData.package_img"
                    class="mt-3"
                    :rules="rules.imageRules"
                    @validation="valid_image"
                  >
                    <template v-slot:activator>
                      <div>
                        <m-button text color="primary"
                          ><v-icon left>image</v-icon>
                          {{
                            !$language ? "Select an image" : "ছবি সিলেক্ট করুন"
                          }}
                        </m-button>
                      </div>
                    </template>
                  </image-input>
                </v-card>
                <v-row
                  ><span
                    class="v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text"
                    >{{ imageValidationMessage }}</span
                  ></v-row
                >
              </v-col>
            </v-row>
            <v-row style="margin-top: 15px">
              <v-col>
                <v-form :ref="formNames[2]" v-model="formValid[2]">
                  <v-text-field
                    v-model="packageData.price"
                    outlined
                    :placeholder="
                      !$language
                        ? 'Enter package price...'
                        : 'প্যাকেজ মূল্য প্রদান করুন'
                    "
                    height="45"
                    type="number"
                    :rules="[digitsOnly, ...positiveNumberRule]"
                  >
                    <template #label>
                      {{ !$language ? "Price" : "মূল্য" }}
                      <span style="color: red">*</span>
                    </template>
                  </v-text-field>
                </v-form>
              </v-col>
              <v-col>
                <v-form :ref="formNames[3]" v-model="formValid[3]">
                  <v-select
                    v-model="packageData.payment_type"
                    outlined
                    :items="paymentTypes"
                    :item-text="!$language ? 'en' : 'bn'"
                    item-value="en"
                    :rules="[(v) => required(v)]"
                  >
                    <template #label>
                      {{ !$language ? "Payment types" : "পেমেন্টের ধরন" }}
                      <span style="color: red">*</span>
                    </template>
                  </v-select>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="packageData.discount_type"
                  outlined
                  :label="!$language ? 'Discount types' : 'ডিসকাউন্টের ধরন'"
                  :items="discountTypes"
                  :item-text="!$language ? 'en' : 'bn'"
                  item-value="en"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="packageData.discount"
                  outlined
                  :label="!$language ? 'Discount' : 'ডিসকাউন্ট'"
                  height="45"
                  type="number"
                  :rules="[...rules.discountRule, ...positiveNumberRule]"
                >
                  >
                </v-text-field>
              </v-col>
            </v-row>
            <p>
              {{ !$language ? "Select a Timeline" : "সময়সীমা নির্বাচন করুন" }}
            </p>
            <v-row>
              <v-col>
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-form :ref="formNames[4]" v-model="formValid[4]">
                      <v-text-field
                        v-model="packageData.start_date"
                        v-bind="attrs"
                        outlined
                        readonly
                        :rules="[required]"
                        v-on="on"
                      >
                        <template #label>
                          {{ !$language ? "From" : "হইতে" }}
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </v-form>
                  </template>
                  <v-date-picker
                    v-model="packageData.start_date"
                    no-title
                    scrollable
                    @input="fromDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="toDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-form :ref="formNames[5]" v-model="formValid[5]">
                      <v-text-field
                        v-model="packageData.expiry_date"
                        v-bind="attrs"
                        outlined
                        readonly
                        :rules="[required]"
                        v-on="on"
                      >
                        <template #label>
                          {{ !$language ? "To" : "পর্যন্ত" }}
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </v-form>
                  </template>
                  <v-date-picker
                    v-model="packageData.expiry_date"
                    no-title
                    scrollable
                    @input="toDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="end">
              <m-rounded-button
                outlined
                color="red accent-3"
                data-qa="cancel-button"
                class="btn-outlined"
                @click="
                  $router.push({ name: 'courses', query: { tab: 'package' } })
                "
              >
                <v-icon left>cancel</v-icon>
                {{ !$language ? "Cancel" : "বাতিল করুন" }}
              </m-rounded-button>
              <m-rounded-button
                depressed
                class="ml-2 btn-outlined"
                @click="savePackage"
              >
                <m-icon left>save_alt</m-icon>
                {{ !$language ? "Save" : "সেভ করুন" }}
              </m-rounded-button>
            </v-row>
          </v-form>
        </v-card>
      </v-container>
    </v-col>
    <!-- <v-col>
            <v-container class="pa-2">
                <v-col>
                    <v-card height="400" width="60%">

                    </v-card>
                </v-col>
            </v-container>
        </v-col> -->
  </v-row>
</template>

<script>
import ImageInput from "~ecf/components/ImageInput";
import { getOwnCourses } from "#ecf/courses/actions";
import PackageService from "./services/PackageService";
import { FieldValidations } from "/global/utils/validations.js";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "PackageCreateForm",
  components: { ImageInput, SubmissionAlertMessage },
  mixins: [FieldValidations],
  data() {
    return {
      imageValidationMessage: undefined,
      showBannerCondition: false,
      selectedCourses: [],
      courses: [],
      activeBatches: [],
      formValid: [],
      packageData: {
        package_title: null,
        price: null,
        payment_type: null,
        discount: null,
        package_img: null,
        discount_type: null,
        start_date: null,
        expiry_date: null,
        items: {},
      },
      selectedBatches: {},
      fromDateMenu: false,
      toDateMenu: false,
      isValidDateRange: false,
      positiveNumberRule: [(v) => this.minValue(v, 0)],
      numberRule: [this.digitsOnly],
      rules: {
        required: [this.required],
        select_required: [(value) => this.requiredSelectField(value)],
        discountRule: [(v) => this.floatOnly(v, "discount")],
        imageRules: [
          (v) => this.fileSizeLessThanOrEqual(v, 2 * 1024 * 1024, "image size"),
        ],
      },
      paymentTypes: [
        { en: "One Time", bn: "এক কালীন" },
        { en: "Monthly", bn: "মাসিক" },
        { en: "Installment", bn: "কিস্তি" },
      ],
      discountTypes: [
        { en: "Percentage", bn: "শতাংশ" },
        { en: "Amount", bn: "পরিমাণ" },
      ],
      formNames: ["title", "courses", "price", "payment", "start", "end"],
    };
  },
  /* mounted() {
        this.$refs.myForm.validate(); 
        
    }, */

  computed: {
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    },
    bannerMessage() {
      return this.$language
        ? "সকল প্রয়োজনীয় তথ্য পূরণ করুন"
        : "You must fill out all the mandatory fields";
    },
  },

  watch: {
    selectedCourses: {
      handler(value) {
        let selected = {};
        value.forEach((course) => {
          if (Object.keys(this.selectedBatches).includes(course.course_id)) {
            selected[course.course_id] = this.selectedBatches[course.course_id];
          }
        });
        this.selectedBatches = selected;
      },
    },
  },

  async created() {
    await this.getCourses();
  },

  methods: {
    valid_image(value) {
      this.imageValidationMessage = value;
    },
    async getCourses() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      this.courses = await getOwnCourses(this.$store);
    },
    async getBatchesUnderCourses(id, partner) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      await this.$store.dispatch("batch/list", {
        courseId: id,
        partner_code: partner,
      });
      this.activeBatches = this.$store.getters["batch/batches"][id];

      //let batches = this.$store.getters["batch/batches"][id];
      //this.activeBatches = batches;

      /* batches.forEach((batch) => {
                let isActive = new Date() < new Date(batch.end_date).getTime();
            
                if (isActive) {
                    this.activeBatches.push(batch);
                }
            }); */
    },
    isEmptyObject(obj) {
      return JSON.stringify(obj) === "{}";
    },

    getFormattedCurrentDate() {
      let currentDate = new Date();
      let dd = currentDate.getDate();
      let mm = currentDate.getMonth() + 1;
      let yyyy = currentDate.getFullYear();
      return yyyy.toString() + "-" + mm.toString() + "-" + dd.toString();
    },

    async savePackage() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"]);
        return;
      }
      let allValid = false;
      if (this.formValid.length === 6) {
        for (let index = 0; index < this.formValid.length; index++) {
          let isEmpty = this.formValid[index];
          if (!isEmpty) {
            allValid = false;
            break;
          } else allValid = true;
        }
      }
      if (!allValid) {
        this.formNames.forEach((item) => {
          this.$refs[item].validate();
        });
        if (!this.showBannerCondition) this.showBannerCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      this.isValidDateRange =
        new Date(this.packageData.start_date).getTime() <
          new Date(this.packageData.expiry_date).getTime() &&
        new Date(this.packageData.start_date).getTime() >=
          new Date(this.getFormattedCurrentDate()).getTime() &&
        new Date(this.packageData.expiry_date).getTime() >
          new Date(this.getFormattedCurrentDate()).getTime();
      if (!this.isValidDateRange) {
        this.$root.$emit("alert", [
          undefined,
          "Please give a valid Date Range",
        ]);
        return;
      }

      let courses = [];
      if (this.selectedCourses != []) {
        this.selectedCourses.forEach((course) => {
          let c = {};
          let batches = [];

          if (
            this.selectedBatches[course.course_id] != undefined &&
            this.selectedBatches[course.course_id].length > 0
          ) {
            this.selectedBatches[course.course_id].forEach((batch) => {
              let b = {};
              // if (course.course_id == batch.course_id) {
              b.batch_id = batch.batch_id;
              b.batch_title = batch.batch_title;
              // }
              if (!this.isEmptyObject(b)) {
                batches.push(b);
              }
            });
          }
          // else{
          //     this.$root.$emit("alert", [undefined, "Select minimum one batch"]);
          // }
          c.batches = batches;
          c.course_id = course.course_id;
          c.course_title = course.course_title;

          courses.push(c);
        });
      }

      this.packageData.items.courses = courses;
      Object.keys(this.packageData).forEach((index) => {
        if (
          this.packageData[index] === null ||
          this.packageData[index] === undefined
        ) {
          delete this.packageData[index];
        }
      });

      try {
        let result = await PackageService.createPackage(
          this.ownPartnerCode,
          this.packageData
        );

        if (result.data.content.message == "success") {
          this.$root.$emit("alert", [
            "Success",
            "Package is successfully created",
          ]);

          this.$router.push({ name: "courses", query: { tab: "package" } });
        } else {
          this.$root.$emit("alert", [undefined, result.data.content]);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss"></style>
