var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "/teachers/courses?tab=package",
          "page-title": !_vm.$language ? "Create Package" : "প্যাকেজ তৈরি",
        },
      }),
      _c("package-create-form"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }