<template>
  <v-container>
    <page-title
      previous-location="/teachers/courses?tab=package"
      :page-title="!$language ? 'Create Package' : 'প্যাকেজ তৈরি'"
      class="mt-5"
    >
    </page-title>
    <!-- <v-card class = "ma-5 pa-5" style="border-radius: 10px 10px">
        <package-create-form>
        </package-create-form>
        </v-card> -->
    <package-create-form> </package-create-form>
  </v-container>
</template>

<script>
import PackageCreateForm from "../../modules/package/PackageCreateForm.vue";
export default {
  name: "PackageCreatePage",
  components: { PackageCreateForm },
  data() {
    return {
      previousPage: {
        name: "courses",
        query: {
          selectedTab: 2
        }
      }
    };
  }
};
</script>

<style></style>
